import React from "react"
import { graphql } from "gatsby"

// import app components
import { FlexibleContent } from "components"

export default function DefaultPageTemplate(props) {
  const {
    data: {
      page: {
        title,
        uri,
        acf: { modules },
      },
    },
  } = props

  return (
    <>
      {!!modules && (
        <FlexibleContent
          rows={modules}
          data={{
            title,
            uri,
          }}
        />
      )}
    </>
  )
}

export const CollectionQuery = graphql`
  fragment PageFragment on WpPage {
    title
    slug
    uri
    acf {
      modules {
        __typename
        ... on WpPage_Acf_Modules_TextImageColumns {
          columns
          imagePlacement
          textAlignment
          items {
            text
            mobileImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 90, width: 768, layout: CONSTRAINED)
                }
              }
            }
          }
          desktopBannerImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 90, layout: FULL_WIDTH)
              }
            }
          }
        }
        ... on WpPage_Acf_Modules_CompleteTheLook {
          headline
          items {
            overrideProductImage
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
            product {
              __typename
              ... on WpProduct {
                id
                uri
                product {
                  ...ProductFragment
                }
              }
            }
          }
        }
        ... on WpPage_Acf_Modules_Accordion {
          headline
          items {
            expanded
            headline
            content
          }
        }
        ... on WpPage_Acf_Modules_TwoImages {
          image1 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 85, layout: FULL_WIDTH)
              }
            }
          }
          image2 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 85, layout: FULL_WIDTH)
              }
            }
          }
          variant
        }
        ... on WpPage_Acf_Modules_Columns {
          size
          columns
          items {
            button {
              target
              title
              url
            }
            buttonStyle
            text
          }
        }
        ... on WpPage_Acf_Modules_Hero {
          useDifferentVideoForMobile
          keepAspectRatio
          videoType
          youtubeUrl
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 90, layout: FULL_WIDTH)
              }
            }
          }
          video {
            localFile {
              publicURL
            }
          }
          autoplay
          mobileVideo {
            keepAspectRatio
            videoType
            youtubeUrl
            video {
              localFile {
                publicURL
              }
            }
          }
        }
        ... on WpPage_Acf_Modules_Text {
          text
          button {
            target
            title
            url
          }
          buttonStyle
        }
        ... on WpPage_Acf_Modules_Form {
          formId
        }
        ... on WpPage_Acf_Modules_BannerImage {
          desktopImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 90, layout: FULL_WIDTH)
              }
            }
          }
          mobileImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 960, quality: 90, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }

  query DefaultTemplate($id: String!) {
    page: wpPage(id: { eq: $id }) {
      ...PageFragment
    }
  }
`
